<template>
  <div class="favorites">
    <div class="favorites__container _container">
      <BreadCrumbs :items="[[$t('main'), '/'], [$t('favorites')]]" />
      <h1 class="favorites__title main-title">{{ $t("myFavorites") }}</h1>
      <div class="favorites__body">
        <div class="favorites__items" v-if="favorites.length > 0">
          <div
              class="favorites__item"
              v-for="(product, i) in mappedFavorites"
              :key="i"
          >
            <ProductItem
                @change="update"
                @click="goToProduct(product)"
                :isFavorites="true"
                :product="product"
                :title="newArray[i]"
            ></ProductItem>
          </div>
        </div>
        <div class="favorites__text" v-else>{{ $t("emptyFavorites") }}</div>
        <router-link v-if="!favorites.length" to="/">
          <button class="favorites__btn main-btn">
            {{ $t("continueShopping") }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductItem from "@/components/ProductItem";
import axios from "axios";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";

export default {
  components: { ProductItem, BreadCrumbs },
  data() {
    return {
      newArray: [],
    };
  },
  computed: {
    ...mapState(["favorites"]),
    mappedFavorites() {
      return this.favorites.map((favorite) => ({
        ...favorite, // Spread all properties from the favorite
        id: Number(favorite.product_id), // Convert `product_id` to a number and assign it to `id`
        quantity: favorite.quantity || 1, // Ensure quantity defaults to 1 if undefined
      }));
    },
  },
  created() {
    this.fetchFavorites();
  },
  methods: {
    ...mapActions(["fetchFavorites"]),
    update() {
      this.fetchFavorites();
    },
    goToProduct(product) {
      if (!product.quantity) {
        product.quantity = 1;
      }
      //this.$router.push(`/product/${product.slug}`);
    },
  },
};
</script>

<style lang="scss">
.favorites {
  padding: 40px 0 92px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 112px 0;
  }

  &__title {
    margin: 0 0 49px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 45px 0;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 0 40px 0;
    @media (max-width: $mobileSmall + px) {
      gap: 10px;
      margin: 0 0 35px 0;
    }
  }

  &__item {
    align-self: normal;
    width: 288px;
    @media (max-width: $tablet + px) {
      flex: 0 1 calc(33% - 11px);
    }
    @media (max-width: $mobile + px) {
      flex: 0 1 calc(50% - 11px);
    }
    @media (max-width: $mobileSmall + px) {
      flex: 0 1 calc(50% - 5px);
    }
  }

  &__text {
    margin: 0 0 20px 0;
  }

  &__btn {
    padding: 0 22px;
    @media (max-width: $mobileSmall + px) {
      width: 100%;
    }
  }
}
</style>